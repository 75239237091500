import React from "react";
import FeatureImage from "./FeatureImage";
import {Box, XCol, XRow} from "../xui/Box";
import Avatar from "../Avatar";
import {Link} from "gatsby";
import {ThemedButton} from "../xui/ThemedButton";
import Icon from "../Icon";

const Image = ({img, imgAlt, isRight, to}) => {
  return (
    <XRow fillParent justify="center" width="60%" md_width="100%">
      <FeatureImage img={img} alt={imgAlt} fade={isRight ? "fromRight" : "fromLeft"} to={to} />
    </XRow>
  );
};

export const MiniQuote = ({children, authorName, authorInfo, companyName, img}) => (
  <XCol sp={3} py={1}>
    <Box as="p" color="body" size="sm" lineHeight="wide" italic>
      {children}
    </Box>
    <XRow sp={4} align="center">
      {img && <Avatar name={img} nonGray />}
      <XCol>
        <Box color="body" size="sm" bold>
          {authorName}
        </Box>
        <Box color="hint" size="xs">
          {authorInfo ? `${authorInfo} / ` : ""}
          {companyName}
        </Box>
      </XCol>
    </XRow>
  </XCol>
);

const WrapLink = ({titleUrl, children}) =>
  titleUrl ? (
    <XRow align="center" sp={1} width="100%" triggerButtonHover>
      <Link to={titleUrl}>{children}</Link>
      <Box ml="auto">
        <ThemedButton to={titleUrl} size="smSquare">
          <Icon.ArrowRight />
        </ThemedButton>
      </Box>
    </XRow>
  ) : (
    children
  );

const ThemedSingleFeature = ({
  title,
  titleUrl,
  description,
  img,
  imgAlt,
  imgRight,
  childAsDescription,
  children,
}) => (
  <XRow sp={8} align="center" md_col>
    {!imgRight && <Image img={img} imgAlt={imgAlt} to={titleUrl} />}
    <XCol fillParent align="start" width="40%" md_width="100%" md_order={1} md_aligh="center">
      <XCol sp={6} align="start" width="100%">
        <XCol sp={4} align="start" maxWidth="md" md_maxWidth="100%" width="100%">
          {title && (
            <WrapLink titleUrl={titleUrl}>
              <Box
                as="h3"
                bold
                size="xxl"
                color="secondary"
                hover_color={titleUrl ? "buttonSecondary" : null}
              >
                {title}
              </Box>
            </WrapLink>
          )}
          {(description || childAsDescription) && (
            <>
              {title && <Box as="hr" borderWidth="0" bg="themeAccent" height="3px" width="2.5" />}
              <Box color="body" size="lg" lineHeight="wide" width="100%">
                {childAsDescription ? children : description}
              </Box>
            </>
          )}
        </XCol>
        {!childAsDescription && children}
      </XCol>
    </XCol>
    {imgRight && <Image img={img} imgAlt={imgAlt} isRight to={titleUrl} />}
  </XRow>
);
export default ThemedSingleFeature;
