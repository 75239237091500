import React from "react";
import {Link} from "gatsby";
import cx from "./xui/cx";
import {uiStyles} from "./xui/xui.css";
import {Box, XCol, XRow} from "./xui/Box";
import ThemeBox from "./bricks/ThemeBox";
import {themeBright} from "../page-styles/landingpage.css";

const ActiveAwareLink = ({className, ...rest}) => (
  <Link
    getProps={({isCurrent}) => ({
      className: cx(
        className,
        ...(isCurrent
          ? [
              uiStyles.color.primary,
              uiStyles.borderBottomWidth[1],
              uiStyles.borderColor.primaryButton,
            ]
          : [uiStyles.color.body])
      ),
    })}
    {...rest}
  />
);

const LinkRow = ({title, links}) => (
  <XCol sp={3}>
    <Box as="h4" color="buttonSecondary" size="sm">
      {title}
    </Box>
    <XRow sp={4} wrap>
      {links.map(({label, url}) => (
        <Box as={ActiveAwareLink} to={url} key={label} size="sm" hover_color="purple500">
          {label}
        </Box>
      ))}
    </XRow>
  </XCol>
);

const featureLinks = [
  {label: "Cards, Decks & Projects", url: "/features/cards-decks-and-projects/"},
  {label: "Conversations & Notifications", url: "/features/conversations-and-notifications/"},
  {label: "The Hand", url: "/features/unique-hand-design/"},
  {label: "Time Tracking", url: "/features/time-tracking/"},
  {label: "Search & Order", url: "/features/search-and-order/"},
  {label: "Hero Cards & Journeys", url: "/features/hero-cards-and-journeys/"},
  {label: "Metrics & Reports", url: "/features/metrics-and-reports/"},
  {label: "Milestones", url: "/features/milestones/"},
  {label: "Open Development", url: "/features/open-development/"},
];

const integrationLinks = [
  {label: "Discord", url: "/features/discord-integration/"},
  {label: "Trello Importer", url: "/features/vs-trello#importer/"},
  {label: "Slack", url: "/features/slack-integration/"},
  {label: "GitHub, Bitbucket & GitLab", url: "/features/source-control-integration/"},
];

const vsLinks = [
  {label: "Trello", url: "/features/vs-trello/"},
  {label: "Jira", url: "/features/vs-jira/"},
  {label: "HacknPlan", url: "/features/vs-hacknplan/"},
];

const FeatureOverview = () => (
  <ThemeBox theme={themeBright} size="md" py={7} bg="gray100" sp={5}>
    <Box as="h3" preset="bold" color="primary" size="xl">
      Discover more
    </Box>
    <LinkRow title="Features" links={featureLinks} />
    <LinkRow title="Integrations" links={integrationLinks} />
    <LinkRow title="Codecks vs" links={vsLinks} />
  </ThemeBox>
);

export default FeatureOverview;
