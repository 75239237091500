import React, {useEffect, useState} from "react";
import Img from "gatsby-image";
import {useStaticQuery, graphql, Link} from "gatsby";
import {Box} from "../xui/Box";
import {createObserver} from "../ColorScroller";

const featureImageQuery = graphql`
  {
    allFile(
      filter: {
        relativeDirectory: {glob: "{feature-images/*,feature-images,hero-images}"}
        extension: {ne: "svg"}
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 960, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

const FeatureImage = ({img, alt, styles, to, fade, ...rest}) => {
  const [node, setNode] = useState(null);
  const [visible, setVisible] = useState(false);
  const {allFile} = useStaticQuery(featureImageQuery);
  const imgData = allFile.nodes.find(n => n.relativePath.endsWith(img));

  useEffect(() => {
    if (node && node.imageRef.current && fade) {
      return createObserver({
        node: node.imageRef.current,
        onIntersect: () => setVisible(true),
        threshold: 0.5,
      });
    }
  }, [node, fade]);

  if (!imgData) return null;
  const {fluid} = imgData.childImageSharp;

  const width = Math.min(100, fluid.aspectRatio * 100 * 0.66);

  const comp = (
    <Box
      as={Img}
      fluid={fluid}
      style={width !== 100 ? {width: `${width}%`, ...styles} : styles}
      alt={alt || ""}
      rounded="md"
      borderWidth={1}
      borderColor="imgBorder"
      elevation="lg"
      width="100%"
      relative
      zIndex={1}
      ref={setNode}
      fadeEffect={!fade ? null : visible ? "toTarget" : fade}
      {...rest}
    />
  );

  return to ? (
    <Link to={to} css={{display: "block", width: "100%"}}>
      {comp}
    </Link>
  ) : (
    comp
  );
};

export default FeatureImage;
